(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _helpers = _interopRequireDefault(require("./helpers"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HeaderV2Tagging = function HeaderV2Tagging() {
  _classCallCheck(this, HeaderV2Tagging);

  var self = this;
  /* Elements to target (common / desktop) */

  /* Selectors for click_header_nav */

  var click_header_nav_logo_s = document.querySelector('.kH-logo img');
  var click_header_nav_parent_menu_m = document.querySelectorAll('.kH-m-container .kH-m-menu>a,.kH-m-container .kH-m-menu>button');
  var click_header_nav_quick_access_m = document.querySelectorAll('.kH-nav div.kH-submenu:not(.kH-n-subsubmenu)>.kH-sm-list>li>a, .kH-nav div.kH-submenu:not(.kH-n-subsubmenu)>.kH-sm-list>li>button');
  var click_header_nav_secondary_menu_s = document.querySelector('.kH-m-burger>button');
  /* Triggers for click_header_nav */

  self.buildEventListenersFromElement(click_header_nav_logo_s, 'click_header_nav', 'logo', 'logo');
  self.buildEventListenersFromElement(click_header_nav_parent_menu_m, 'click_header_nav', 'attr_data_level');
  self.buildEventListenersFromElement(click_header_nav_quick_access_m, 'click_header_nav', 'quick_actions');
  self.buildEventListenersFromElement(click_header_nav_secondary_menu_s, 'click_header_nav', 'burger_secondary');
  /* Selectors for click_menu_nav */

  var click_menu_nav_child_chapo_m = document.querySelectorAll('div.kH-m-dropdown button.kH-m-d-chapo, div.kH-m-dropdown a.kH-m-d-chapo');
  var click_menu_nav_child_link_m = document.querySelectorAll('div.kH-m-dropdown .kH-m-d-container a');
  var click_menu_nav_close_m = document.querySelectorAll('div.kH-m-dropdown button.kH-m-d-close');
  var click_menu_nav_profile_switcher_m = document.querySelectorAll('div.kHeader-second>.kHeader-second-inner>.kH-droplist div.kH-submenu.kH-dl-submenu ul.kH-sm-list a');
  var click_menu_nav_language_switcher_m = document.querySelectorAll('div.kH-switcher div.kH-submenu.kH-s-submenu ul.kH-sm-list a');
  var click_menu_nav_quick_access_m = document.querySelectorAll('.kH-nav div.kH-n-subsubmenu>.kH-sm-list>li>a,.kH-nav div.kH-n-subsubmenu>.kH-sm-list>li>button');
  /* Triggers for click_menu_nav */

  self.buildEventListenersFromElement(click_menu_nav_child_chapo_m, 'click_menu_nav', 'aria-labelledby');
  self.buildEventListenersFromElement(click_menu_nav_child_link_m, 'click_menu_nav', 'aria-labelledby');
  self.buildEventListenersFromElement(click_menu_nav_close_m, 'click_menu_nav', 'close', 'close');
  self.buildEventListenersFromElement(click_menu_nav_profile_switcher_m, 'click_menu_nav', 'switcher_profile');
  self.buildEventListenersFromElement(click_menu_nav_language_switcher_m, 'click_menu_nav', 'switcher_language');
  self.buildEventListenersFromElement(click_menu_nav_quick_access_m, 'click_menu_nav', 'quick_actions');
  /* Selectors fro click_side_nav */

  var click_side_nav_close_m = document.querySelectorAll('li.kH-m-burger button.kH-p-d-close, li.kH-m-burger button.kH-p-s-close');
  var click_side_nav_back_m = document.querySelectorAll('li.kH-m-burger button.kH-p-s-back, li.kH-m-menu .kH-m-d-back');
  var click_side_nav_child_m = document.querySelectorAll('li.kH-p-listItem button.kH-p-item, li.kH-p-listItem a.kH-p-item, li.kH-p-s-listItem a');
  /* Triggers for click_side_nav */

  self.buildEventListenersFromElement(click_side_nav_close_m, 'click_side_nav', 'close', 'close');
  self.buildEventListenersFromElement(click_side_nav_back_m, 'click_side_nav', 'back', 'back');
  self.buildEventListenersFromElement(click_side_nav_child_m, 'click_side_nav', 'attr_self_data_level');
  /* Elements to target (mobile) */

  /* Selectors for click_header_nav */

  var click_header_nav_open_burger_mobile_s = document.querySelector('div.kH-menu>button.kH-mobileBurger');
  var click_header_nav_close_burger_mobile_s = document.querySelector('div.kH-menu li.kH-mobileClose.kH-m-close');
  /* Triggers for click_header_nav */

  self.buildEventListenersFromElement(click_header_nav_open_burger_mobile_s, 'click_header_nav', 'burger_mobile', 'open');
  self.buildEventListenersFromElement(click_header_nav_close_burger_mobile_s, 'click_header_nav', 'burger_mobile', 'close');
};

HeaderV2Tagging.prototype = {
  buildEventListenersFromElement: function buildEventListenersFromElement(element, eventName, categoryName) {
    var _this = this;

    var forcedText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

    try {
      if (element instanceof NodeList && element.length > 0) {
        // If there is multiple elements
        element.forEach(function (domElement) {
          _this.processDomElement(domElement, eventName, categoryName, forcedText);
        });
      } else {
        // If it is a single element
        element !== null ? this.processDomElement(element, eventName, categoryName, forcedText) : false;
      }
    } catch (e) {// console.error('An error occured while processing data.', e);
    }
  },
  processDomElement: function processDomElement(domElement, eventName, categoryName, forcedText) {
    var _this2 = this;

    domElement.addEventListener('pointerup', function () {
      var processedCategoryName = _this2.buildCategoryName(domElement, categoryName);

      if (processedCategoryName !== null) {
        categoryName = processedCategoryName;
      }

      var elementText;

      if (forcedText !== null) {
        elementText = forcedText;
      } else {
        if (domElement.dataset.wordingItemMenu) {
          elementText = domElement.dataset.wordingItemMenu;
        } else {
          elementText = domElement.innerText;
        }
      }

      var eventParams = {
        'element_category': categoryName,
        'element_text': elementText
      };

      _helpers.default.prototype.pushTaggingEvent(eventName, eventParams);
    });
  },
  buildCategoryName: function buildCategoryName(element, categoryNameAlias) {
    if (categoryNameAlias === 'attr_data_level') {
      return element.parentNode.dataset.level;
    } else if (categoryNameAlias === 'attr_self_data_level') {
      return element.dataset.level;
    } else if (categoryNameAlias === 'aria-labelledby') {
      return element.closest('[aria-labelledby]').getAttribute('aria-labelledby');
    } else if (categoryNameAlias === null) {
      return element.innerText;
    }

    return categoryNameAlias;
  }
};
window.HeaderV2Tagging = HeaderV2Tagging;

},{"./helpers":2}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TaggingHelpers = function TaggingHelpers() {
  _classCallCheck(this, TaggingHelpers);

  var self = this;
};

TaggingHelpers.prototype = {
  pushTaggingEvent: function pushTaggingEvent(event, eventParams) {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push(_objectSpread({
        event: event
      }, eventParams));
    }
  }
};
var _default = TaggingHelpers;
exports.default = _default;

},{}]},{},[1]);
